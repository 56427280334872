import {
    HomeHeroDiv,
    HomeHeroMainDiv,
    HomeHeroMainHeadText,
    HomeHeroMainLinkButton,
    HomeHerorRound,
    RotatingSpan,
} from "../../styles/homeComp/home";
import { HomeHero, HomeMainDiv, HomePricing, HomePricingButton, HomePricingCard, HomePricingContent, HomePricingSelection, HomePricingSelectionButton } from "../../styles/homeComp/homeComponentsStyle";
import { MdArrowOutward } from "react-icons/md";
import { VscCode } from "react-icons/vsc";
import { RiLoginBoxFill } from "react-icons/ri";


import { IoExtensionPuzzleSharp } from "react-icons/io5";

import { FaSquareCheck } from "react-icons/fa6";
import { Button } from "../../styles/reusable";
import Spline from '@splinetool/react-spline';
import { useEffect, useState } from "react";
import { features } from "process";


const HomeComponent = () => {
    const [isMonthlyPlan, setIsMonthlyPlan] = useState<boolean>(false)
    const registerUser = () => {
        window.open(
            "https://marketplace.visualstudio.com/items?itemName=Sixth.sixth",
            "_blank"
        );
    };
    useEffect(() => {


        const blockContainer = document.getElementById("blocks")
        const blockSize = 50
        const screenWidth = window.innerWidth
        const screenHeight = window.innerHeight
        const numCols = Math.ceil(screenWidth / blockSize)
        const numRows = Math.ceil(screenHeight / blockSize)
        const numBlocks = numCols * numRows



        function createBlocks() {
            for (let index = 0; index < numBlocks; index++) {
                const block = document.createElement("div");
                block.classList.add("block");
                block.dataset.index = index.toString();
                block.addEventListener("mousemove", highlightRandomNeighbors);
                blockContainer?.appendChild(block);

            }
        }

        function highlightRandomNeighbors(this: HTMLElement) {


            const index = parseInt(this.dataset.index!);


            const neighbours = [
                index - 1,
                index + 1,
                index + numCols,
                index - numCols,
                index - numCols - 1,
                index - numCols + 1,
                index + numCols + 1,
                index + numCols - 1,
            ].filter(
                (i) =>
                    i >= 0 && i < numBlocks && Math.abs((i % numCols) - (index % numCols)) <= 1
            );

            this.classList.add("highlight");

            setTimeout(() => {
                this.classList.remove("highlight");
            }, 500);

            ShuffleArray(neighbours).slice(0, 1).forEach((nIndex) => {
                const neighbour = blockContainer?.children[nIndex] as HTMLElement;
                if (neighbour) {
                    neighbour.classList.add("highlight");
                    setTimeout(() => {
                        neighbour.classList.remove("highlight");
                    }, 500);
                }
            });
        }

        function ShuffleArray(array: any[]): any[] {
            console.log(array)


            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            console.log(array)
            return array;
        }

        createBlocks()
    }, [])

    return (
        <HomeMainDiv>
            <div id="block-container">
                <div id="blocks"></div>
            </div>

            <HomeHero>
                <section>
                    <div className="left">
                        <h1 className="header" > Ai powered code assistant </h1>
                        <p>
                        Are you ready to revolutionize the way you code? Introducing  Sixth  AI-Powered Code Assistant, your new best friend in software development. This cutting-edge tool harnesses the power of artificial intelligence to transform your coding workflow</p>

                        <Button
                            bg="transparent"
                            border="1px solid white"
                            color="white"
                            hoverBg=" linear-gradient(
                                      103.55deg,
                                      #7152dc 16.12%,
                                      #2886a9 41.87%,
                                      rgba(63, 122, 144, 0.53) 84.45%
                                    )"
                            hoverColor="var(--text-black)"

                        >
                            <a href="vscode://Sixth.sixth-ai/" >  Start coding              </a>
                                  </Button>

                    </div>

                    <div className="right">
                        <div>
                            <Spline style={{ width: "100%", height: "509px" }} scene="https://prod.spline.design/JMutcJEdmIx3Yc61/scene.splinecode"
                            />





                        </div>



                    </div>

                </section>

            </HomeHero>


            <HomeHeroDiv>
                <h1> Increased productivity with </h1>
                <section>
                    {featuresData.map((item, index) =>
                        <div className="hero" id={"item" + index}>
                            <div className="heroImage" >
                                <img src={item.media} alt="sixth"></img>
                            </div>
                            <div className="section">
                                <h1> {item.name}</h1>
                                <p>
                                    {item.description}
                                </p>
                            </div>
                        </div>)
                    }
                </section>
            </HomeHeroDiv>
            <HomeHeroDiv>
                <h1>Ready to get started </h1>
                <section>
                    {getstartedData.map((item, index) =>
                        <div className="hero" id={"item" + index}>
                            {item.media}
                            <div>
                                <h1 className="center">  {item.name}
                                </h1>
                                <p className="center">
                                    {item.description}


                                </p>
                                <p className="center link" >
                                    <a href={item.link} target="_blank" rel="noreferrer"> <span > {item.action}  <MdArrowOutward /> </span></a>

                                </p>
                            </div>


                        </div>)
                    }




                </section>
            </HomeHeroDiv>


            <HomePricing>
                <div className="header" >
                    <h1>
                        Simple pricing for everyone.
                    </h1>
                    <p>
                        Choose an <b> affordable</b> plan that's packed with the best features for engaging your audience, creating customer loyalty, and driving sales.

                    </p>
                    <HomePricingSelection>
                        <HomePricingSelectionButton $isSelected={!isMonthlyPlan}
                            onClick={() => {
                                setIsMonthlyPlan(false);
                            }}>
                            yearly <span>save 25%</span>

                        </HomePricingSelectionButton>
                        <HomePricingSelectionButton $isSelected={isMonthlyPlan} onClick={() => {
                            setIsMonthlyPlan(true);
                        }}>
                            Monthly
                        </HomePricingSelectionButton>



                    </HomePricingSelection>

                </div>
                <HomePricingContent>



                    {
                        planData.map((item, index) =>
                            <HomePricingCard>
                                <div className="head">
                                    <div className="flex-head">
                                        <h2>{item.name}</h2>  {item.isMostPopular?<div className="mostPopular">most popular </div>  :""}  </div>
                                    {
                                        isMonthlyPlan ? <h1>{item.monthPrice} <span>/Monthly</span></h1> : <h1>{item.yearPrice} <span>/year</span></h1>
                                    }



                                    <p className="white">{item.desription} </p>
                                </div>
                                <div className="body">
                                    <ul>
                                        {
                                            item.features.map((feature, index) => <li>
                                                <FaSquareCheck />
                                                <p className="white">{feature} </p>
                                            </li>)
                                        }


                                    </ul>
                                </div>
                                <HomePricingButton href="https://marketplace.visualstudio.com/items?itemName=Sixth.sixth-ai" target="_blank">
                                    start coding
                                </HomePricingButton>


                            </HomePricingCard>)
                    }




                </HomePricingContent>




            </HomePricing>




        </HomeMainDiv>
    );
};


const featuresData = [
    {
        media: "https://raw.githubusercontent.com/precious112/Pstore_backend/refs/heads/master/media/media/edited_needed_one_edit-ezgif.com-optimize.gif",
        name: "AI CODE ASSITANT",
        description: "The AI Chat Code Assistant is a feature designed to help developers with coding tasks. It provides real-time suggestions, debugging help, and tips for optimizing code.",

    },
    {
        media: "https://raw.githubusercontent.com/precious112/Pstore_backend/refs/heads/master/media/media/ezgif.com-optimize%20(4).gif",
        name: " BUG FINDER",
        description: " The Bug Finder is a tool designed to help developers quickly identify and fix bugs in their code. It provides real-time bug detection and suggestions for solutions.",

    },
    {
        media: "https://raw.githubusercontent.com/precious112/Pstore_backend/refs/heads/master/media/media/ezgif.com-optimize%20(4).gif",
        name: "CODE COMPOSER",
        description: " The Code Composer is  designed to help developers generate code snippets based on their input or context. It leverages advanced AI to understand user requirements and create relevant code efficiently.",

    },
]


const planData = [


    {
        name: "personal",
        isMostPopular: true,
        monthPrice: "$9.99",
        yearPrice: "$49.99",
        desription: "Perfect for individual developers or just trying things out.",
        features: [
            " AI auto complete.",
            "Real time vulnerability detection.",
            "In built assistant chat.",
            "Support via discord."

        ]



    },
    {
        name: "Team",
        isMostPopular: false,
        monthPrice: "$29.99",
        yearPrice: "$199.99",
        desription: "Perfect for individual developers or just trying things out.",
        features: [
            " Up to 5 users.",
            "Jira integration.",
            "Advanced dashboard.",
            "Personalized assistant chat."

        ]



    },
]



const getstartedData = [
    {
        media: < IoExtensionPuzzleSharp />,
        name: "Download the Extension",
        link: "https://marketplace.visualstudio.com/items?itemName=Sixth.sixth-ai",
        action: "Download",
        description: "download the extension from the vs code marketplace or install in your editor",

    },
    {
        media: < RiLoginBoxFill />,
        name: "Sign up",
        link: "https://marketplace.visualstudio.com/items?itemName=Sixth.sixth-ai",
        action: "Sing in",
        description: "create a new  account or login if you have an existing account to sync changes,",

    },
    {
        media: < VscCode />,
        name: "Start Coding",
        link: "vscode: sixth.sixth ",
        action: "Open Vs code",
        description: "Dive in and start coding! Turn your ideas into functional code with our AI.",

    },
]
export default HomeComponent;
