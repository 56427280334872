import React, {useEffect } from 'react';
import HomeHero from '../components/homeComps/homeHero';
import Footer from '../components/reusable/footer';
import AppHeader from '../components/reusable/header';
import HomeComponent from '../components/homeComps/homeComponents';

const Home = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return(
        <>
            <div className="main-widget">
                <main>
                     <AppHeader
                              hideContact={false}
                              logo={"/images/sixthWhiteIcon.png"}
                              top="2rem"
                              width="8rem"
                              mobileWidth="8rem"
                            />
                            <HomeComponent></HomeComponent>
                <HomeHero />
              
                {/* <DownloadSection /> 
                <DemoSection/>*/}
                
                </main>

                <Footer />
            </div>
        </>
    )
} 

export default Home;