import React, { useEffect } from "react";
import {
  HeroHeaderWrap,
  LeftHero,
  TextHeader,
  SupplText,
} from "../../styles/styles";

import { IoMdClose } from "react-icons/io";

import { Button } from "../../styles/reusable";
import AppHeader from "../reusable/header";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { DiVisualstudio } from "react-icons/di";


import { Fade, Bounce } from "react-awesome-reveal";
import {
  HomeHeroDiv,
  HomeHeroDivContent,
  HomeHeroDivVid,

  HomeHeroMainDiv,
  HomeHeroMainHeadText,
  HomeHeroMainLinkButton,


  HomeHerorRound,
  ImgHero,
  RotatingSpan,





} from "../../styles/homeComp/home";

import { MdArrowOutward } from "react-icons/md";
import Services from "./services";
import Tools from "./tools";
import TrustedComponent from "./trustedComponents";


const HomeHero = () => {
  const handleClickScroll = () => {
    const element = document.getElementById("vs-code-demo");
    if (element) {
      // Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const registerUser = () => {
    window.open(
      "https://marketplace.visualstudio.com/items?itemName=Sixth.sixth",
      "_blank"
    );
  };
  useEffect(() => {
    const root = document.documentElement;

    const marqueeElementsDisplayed = 4;
    const marqueeContent = document.querySelector("ul.marquee-content");
    if (marqueeContent == null) return;
    console.log(marqueeElementsDisplayed);

    root.style.setProperty(
      "--marquee-elements",
      marqueeContent.children.length.toString()
    );

    for (let i = 0; i < marqueeElementsDisplayed; i++) {
      marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
    }
  }, []);

  return (
    <>
    
      
      


    { // <Services />
      //<Tools />
      //<TrustedComponent />
      }
    </>
  );
};

export default HomeHero;



