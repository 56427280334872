import styled from "styled-components";

interface ImgDivProp {
  mainWidth?: string;
}
export const ImgHero = styled.div<ImgDivProp>`
  margin: 0px auto;
  overflow: hidden;
  height: 450px;
  background: var(--sixth-gradient5);
  backdroup-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  overflow: hidden;
  width: ${(p) => p.mainWidth || "50%"};
  max-width: 1500px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -140px;
`;

interface HeroMainProp {
  bg?: string;
  height?: string;
}

export const HomeHeroDiv = styled.div<HeroMainProp>`
  border-bottom: 1px solid transparent;
  border-left: 1px solid transparent;
  max-width: 1400px;

  margin: 0 auto;
  background: ${(p) =>
    p.bg ||
    `linear-gradient(103.55deg, #7152dc 16.12%,#2886a9 41.87%,rgba(63, 122, 144, 0.53) 84.45%) border-box`};
  height: ${(p) => p.height || "auto"};
  > h1 {
    text-align: center;
    text-transform: uppercase;
    background: rgb(12, 12, 12);
    backdrop-filter: blur(6px);
    border-bottom: 2px solid rgba(63, 122, 144, 0.53);
    border-right: 2px solid rgba(63, 122, 144, 0.53);
    padding: 36px 0 36px;
    margin: 0;
  }
  .heroImage {
    height: 300px;
    > img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
  .link {
    margin-top: 0;
    cursor: pointer;
    color: #2886a9;

    transition: all 0.3s;
    &:hover {
      color: white;
    }
  }
  .hero {
    background: rgba(12, 12, 12, 67);
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    border-right: 2px solid rgba(63, 122, 144, 0.53);

    box-sizing: border-box;
    min-width: 300px;
    .center {
      text-align: center;
    }
    > svg {
      width: 50px;
      height: 50px;
      margin: 40px auto;
    }
    > div {
      > h1 {
        padding: 8px 20px;
        font-size: 1.6rem;
        font-weight: 600;
        margin: 0px;
      }
      > p {
        padding: 8px 20px;
        font-size: 1.2rem;
        line-height: 1.4em;
        font-weight: 400;
      }
      border-bottom: 2px solid rgba(63, 122, 144, 0.53);
    }
    .section {
      flex:1;
    }
  }
  > section {
    flex-wrap: wrap;
    display: flex;
  }
`;

export const HomeHeroMainDiv = styled.div`
  min-height: 70vh;
  background: black;
  max-width: 1400px;
  margin: 0 auto;
  border-bottom: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  overflow: hidden;
  position: relative;
  background: linear-gradient(black, rgb(12, 12, 12)) padding-box,
    linear-gradient(
        103.55deg,
        #7152dc 16.12%,
        #2886a9 41.87%,
        rgba(63, 122, 144, 0.53) 84.45%
      )
      border-box;
`;

export const HomeHeroMainHeadText = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  > h1 {
    font-size: 7.6em;
    font-weight: 800;

    text-transform: uppercase;
    padding: 50px 0 0;
    letter-spacing: -11px;
    margin: 0 auto;
    text-align: center;
  }
  .span {
    background: white;
    color: black;
    display: flex;
    justify-content: space-between;
    border-radius: 6px 50px 50px 7px;
    align-items: center;
    padding: 0 10px 0 40px;

    h1 {
      font-size: 2.6em;
      font-weight: 300;
      text-transform: uppercase;
      padding: 10px 0;
      margin: 0 auto;
      text-align: left;
      color: black;
    }
    > span {
      > p {
        font-size: 1.5em;
        padding: 0;
        margin: 0;
        transform: translateY(-8px);
        transition: all 0.8s;
        line-height: 1em;
      }
      display: flex;
      height: auto;
      justify-content: space-between;
      gap: 1rem;
      align-items: baseline;
      text-transform: capitalize;
      padding: 10px 0;
      line-height: 10px;
      cursor: pointer;
      font-weight: 600;
      z-index: 3;
      > div {
        position: relative;
      }

      :hover {
        > p {
          transform: translateY(-18px);
        }
        > div {
          color: var(--sixthLightPurple);
          ::after {
            width: 100px;
            height: 100px;
            transition: all 1s;
          }
          ::before {
            width: 0px;
            height: 0px;
            transition: all 0.4s;
          }
        }
      }
    }
  }
  @media (max-width: 1380px) {
    margin-top: 3rem;
    margin-bottom: 1rem;
    > h1 {
      font-size: 7em;
    }
  }
  @media (max-width: 1214px) {
    > h1 {
      font-size: 6.5em;
    }
  }
  @media (max-width: 1125px) {
    > h1 {
      font-size: 6em;
    }
  }
  @media (max-width: 1024px) {
    > h1 {
      font-size: 5em;
      letter-spacing: -6px;
    }
    .span {
      h1 {
        font-size: 1.8em;
        font-weight: 300;
        padding: 10px 0;
      }
    }
  }
  @media (max-width: 950px) {
    > h1 {
      font-size: 4.5em;
      letter-spacing: -6px;
    }
  }
  @media (max-width: 780px) {
    > h1 {
      font-size: 3.5em;
      letter-spacing: -2px;
    }
    .span {
      h1 {
        font-size: 1.3em;
      }
    }
  }
  @media (max-width: 680px) {
    > h1 {
      font-size: 3em;
    }
    .span {
      h1 {
        font-size: 1em;
      }
    }
  }
  @media (max-width: 580px) {
    > h1 {
      font-size: 2.5em;
    }
    .span {
      h1 {
        font-size: 1em;
        font-weight: 400;

        padding: 10px 0;
      }
      padding: 0 1rem;
      > span {
        > div {
          position: relative;
        }
        align-items: center;

        > p {
          font-size: 1em;
          font-weight: 800;
          transform: translateY(0px);
        }

        :hover {
          > p {
            transform: translateY(-18px);
          }
          > div {
            color: var(--sixthLightPurple);
            ::after {
              width: 50px;
              height: 50px;
              transition: all 1s;
            }
            ::before {
              width: 0px;
              height: 0px;
              transition: all 0.4s;
            }
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    > h1 {
      font-size: 1.7em;
      font-weight: 600;
      padding: 0px 0 0;
      letter-spacing: -1px;
      margin: 0 auto;
      text-align: center;
    }

    .span {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 6px 50px 50px 7px;

      padding: 0 10px 0 0px;

      h1 {
        font-size: 1em;
        padding: 10px 0;
      }
      > span {
        display: none;
        > div {
          position: relative;
        }

        :hover {
          > p {
            transform: translateY(-18px);
          }
          > div {
            color: var(--sixthLightPurple);
            ::after {
              width: 50px;
              height: 50px;
              transition: all 1s;
            }
            ::before {
              width: 0px;
              height: 0px;
              transition: all 0.4s;
            }
          }
        }
      }
    }
  }
`;

export const HomeHeroMainLinkButton = styled.div`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  z-index: 1;
  color: white;

  > svg {
    width: 500px;
    height: 300px;

    padding: 0;
    margin: 0;
  }
  ::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 0px;

    height: 0px;
    border-radius: 100%;
    transition: all 0.4s;
    background-position: center;
    background: linear-gradient(
      103.55deg,
      #7152dc 16.12%,
      #2886a9 41.87%,
      rgba(63, 122, 144, 0.53) 84.45%
    );

    z-index: -1;
  }
  ::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    background: black;
    height: 100px;
    border-radius: 100%;
    transition: all 1s;
    background-position: center;
    z-index: -1;
  }
  @media (max-width: 450px) {
    display: none;
  }
`;

export const HomeHeroDivVid = styled.div`
  width: fit-content;
  margin: 10px auto;
  padding: 16px;
  border-radius: 12px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  gap: 20px;
  background: white;
  color: black;

  > svg {
    width: 50px;
    height: 50px;
  }
`;

export const HomeHeroDivContent = styled.div`
  min-height: 30vh;
  width: 100%;
  border: 0px 1px 0px 1px solid white;
  display: flex;
  padding: 0px;
  margin: 0 auto;
  margin-top: 100px;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 50px;
    margin: 0;
    padding: 0;
    width: 100%;
    flex-direction: column-reverse;
    > div {
      width: 90%;
      padding: 0 1rem;
      text-align: center;
      margin-bottom: 2rem;

      > h2 {
        line-height: 45px;
        font-size: 35px;
      }
    }
  }
`;

export const HomeHerorRound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  postion: absolute;
  left: 50%;
  bottom: -30px;
  z-index: 10;

  width: 100px;
  height: 100px;
  position: relative;
  text-align: center;
  background: white;
  color: black;
  border-radius: 50%;
  --noOfItems: 16;
  > svg {
    width: 30px;
    height: 30px;
  }
`;
export const RotatingSpan = styled.span`
  --units: 1;
  --rotationUnit: calc((1turn / var(--noOfItems)) * var(--units, 1));
  position: absolute;
  height: calc(100% + 3px);
  width: calc(100% + 3px);
  font-weight: 800;
  font-size: 13px;
  top: -2px;
  left: -2px;

  transform: rotate(var(--rotationUnit));
  transform-origin: center;
  transition: ;

  &:nth-child(2) {
    --units: 2;
  }
  &:nth-child(3) {
    --units: 3;
  }
  &:nth-child(4) {
    --units: 4;
  }
  &:nth-child(5) {
    --units: 5;
  }
  &:nth-child(6) {
    --units: 6;
  }
  &:nth-child(7) {
    --units: 7;
  }
  &:nth-child(8) {
    --units: 8;
  }
  &:nth-child(9) {
    --units: 9;
  }
  &:nth-child(10) {
    --units: 10;
  }
  &:nth-child(11) {
    --units: 11;
  }
  &:nth-child(12) {
    --units: 12;
  }
  &:nth-child(13) {
    --units: 13;
  }
  &:nth-child(14) {
    --units: 14;
  }
  &:nth-child(15) {
    --units: 15;
  }
  &:nth-child(16) {
    --units: 16;
  }
`;
