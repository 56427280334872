import styled, { keyframes } from "styled-components";

const texClip = keyframes`
 to {
    background-position: 200% center;
  }
`;
export const HomeMainDiv = styled.div`
  margin-bottom: 100px;
  margin-left: 16px;
  margin-right: 16px;
  
  #block-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    
  }
  #blocks {
    background-color:transparent;
    width: 105vw;
    height: 100vh;
    display: flex;
    flex-wrap:wrap;
    justify-content: flex-start;
    align-contents: flex-start;
    overflow: hidden;

  }
  .block {
    width: 50px;
    height: 50px;
    background-color:red);


    border: 0.5px solid  rgba(171, 175, 186, 0.01);
    transition: border-color 0.3s ease;
  }

  .highlight {
    border-color: #ebfb1d;
   
    
  }
`;

export const HomeHero = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  border-bottom: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;

  color: rgb(171, 175, 186);
  >div{
  flex:1;
  width;100%;}

  > section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3rem 2.4% 3rem;
    p{
     font-size: 20px;
    }
    > div {
      width: 49%;
      min-width: 300px;
    }
  }
  
  background: linear-gradient(black, rgb(12, 12, 12)) padding-box,
    linear-gradient(
        103.55deg,
        #7152dc 16.12%,
        #2886a9 41.87%,
        rgba(63, 122, 144, 0.53) 84.45%
      )
      border-box;
  .header {
    font-size: 5.5rem;
    margin: 0;
    line-height: 1.2em;
    text-transform: uppercase;
    font-weight: 900;
    background: linear-gradient(
      to right,
      #095fab 10%,
      rgb(146, 14, 135) 50%,
      #57d75b 60%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${texClip} 7s linear infinite;
  }
  @media (max-width: 450px) {
  >section{
   // flex-direction:column-reverse;

  }
  .left,.right{
  flex:1;
  width:100%;
  
  }
    .header {
    font-size: 3.8rem;
    margin: 0;
    line-height: 1.2em;
   
    
  }
  }
`;

export const HomePricing = styled.div`
  border-bottom: 1px solid transparent;
  border-left: 1px solid transparent;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 100;

  b {
    color: white;
  }

  background: linear-gradient(
      103.55deg,
      #7152dc 16.12%,
      #2886a9 41.87%,
      rgba(63, 122, 144, 0.53) 84.45%
    )
    border-box;

  .header {
    text-align: center;
    padding: 49px;
    > p {
      max-width: 600px;
      margin: 0 auto;
      padding: 50px 0 0;
      font-size: 1.4rem;
      color: hsl(225 10% 70%);
    }
    > h1 {
      margin: 0;
      font-size: 3.4rem;

      text-transform: capitalize;
    }
  }
  > div {
    border-bottom: 2px solid rgb(0, 187, 255);
    border-right: 2px solid rgba(63, 122, 144, 0.53);
  }
`;

export const HomePricingContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const HomePricingSelection = styled.div`
  width: fit-content;
  padding: 4px;
  margin: 38px auto 0;
  border: 3px solid rgb(0, 187, 255);
  border-radius: 100px;
  font-size: 1.24rem;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  cursor: pointer;
  gap: 8px;
  backdrop-filter: blur(116px);
  background: rgba(12, 12, 12, 0.65);
`;
interface HomePricingSelectionButtonProp {
  $isSelected?: boolean;
}
export const HomePricingSelectionButton = styled.section<HomePricingSelectionButtonProp>`
  background: ${(P) => (P.$isSelected ? " rgb(40, 134, 169)" : "")};
  transition: all 0.7s;
  padding: 8px;
  border-radius: 100px;
  color: hsl(225 10% 70%);
  > span {
    color: white;
  }
`;

export const HomePricingCard = styled.div`
  min-width: 350px;
  flex: 1;

  display: flex;
  justify-content: start;
  flex-direction: column;
  backdrop-filter: blur(26px);

  border-right: 2px solid rgb(0, 187, 255);
  .body {
    flex: 1;
    color: white;
  }
  .white {
    color: white;
  }
  .head {
    flex: 1;
  }

  > div {
    padding: 16px;
    align-items: space-between;
    border-bottom: 2px solid rgb(0, 187, 255);
  }
  p,
  span {
    margin: 0px;
    font-size: 1.24rem;
    font-weight: 600;
    color: white;
    color: hsl(225 10% 70%);
  }

  h2 {
    font-size: 1.24rem;
    font-weight: 600;
    color: hsl(225 10% 70%);
    margin: 0px;
  }
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
  }
  .flex-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mostPopular {
    width: fit-content;
    padding: 8px;
    font-size: 1.01rem;
    font-weight: 600;
    color: white;
    border-radius: 100px;
    text-transform: uppercase;
    background: linear-gradient(
      103.55deg,
      rgb(138, 106, 255) 16.12%,
      rgb(0, 185, 252) 41.87%,
      rgba(20, 183, 243, 0.53) 84.45%
    );
  }
  li {
    display: flex;
    align-items: center;
    padding: 0 0 16px;
    gap: 16px;
    > svg {
      width: 26px;
      height: 26px;
    }
  }
     @media (max-width: 450px) {
     li {
    
    padding: 0 0 8px;
    gap: 8px;
   
  }
     }
`;

export const HomePricingButton = styled.a`
  color: white;
  text-align: center;
  cursor: pointer;
  transition: all 0.6s;

  background: rgba(12, 12, 12, 0.55);
  backdrop-filter: blur(100px);
  margin: 0px;
  font-size: 1.24rem;
  border-radius: 0px;
  font-weight: 600;
  padding: 24px;
  color: white;

  text-transform: capitalize;

  &:hover {
    color: rgb(171, 175, 186);
  }
`;
