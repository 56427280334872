import styled from "styled-components";

export const HeroHeaderWrap = styled.div`
  position: relative;
  overflow:hidden;
  background: var(--main-bg);

  @media (max-width: 450px) {
    height: auto;
  }
`;

interface HeroMainProp {
  bg?: string;
  height?: string;
}
export const HeroMain = styled.div<HeroMainProp>`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0px;
  width: 84%;
  margin: 2rem 0;
  background: ${(p) => p.bg || "transparent"};
  padding: 2rem 4%;
  height: ${(p) => p.height || "auto"};

  @media (max-width: 1440px) {
    gap: 30px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    width: auto;
    gap: 0;
    padding: 0 0 3rem 3rem;
    margin: 0px;
  }
`;

export const LeftHero = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  height: 400px;
  > img {
    wufth: 100%;
    height: 100%;
    object-fit: cover;
  }

  > div {
    width: 100%;
    padding: 0;
    line-height: 0px;

    @media (max-width: 450px) {
      padding: 5rem 0% 2rem 0%;
    }
  }

  @media (max-width: 1000px) {
    margin: 0 auto;
    width: 70%;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;
interface HeroProp {
  flexStyle?: string;
}
export const RightHero = styled.div<HeroProp>`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0 0 0;
  position: relative;
  justify-content: ${(p) => p.flexStyle || " flex-start"};

  @media (max-width: 450px) {
    width: 100%;
    margin: 5rem 0 0 0;

    > img {
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    margin: 7rem 0 0 0;
  }
`;

export const UpperBound = styled.img`
  position: absolute;
  top: -2rem;
  right: -2rem;
  width: 250px;

  @media (max-width: 450px) {
    width: 180px;
    right: -1rem;
  }
`;

export const BelowBound = styled.img`
  position: absolute;
  bottom: 3rem;
  left: 4rem;
  width: 250px;

  @media (max-width: 450px) {
    width: 180px;
    left: 0rem;
  }
`;

export const ClassyWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  top: 1rem;

  > div {
    width: 75px;
    height: 12px;
    background: #a2ad1f;
  }

  > h3 {
    letter-spacing: 0.3em;
    color: #3e3e0d;
    font-size: 20px;
    font-weight: 400;
  }

  @media (max-width: 450px) {
    > h3 {
      font-size: 16px;
    }
  }
`;

export const TextHeader = styled.h1`
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 1.2em;
  margin-top: 1rem;

  @media (max-width: 1000px) {
    font-size: 25px;
  }

  @media (min-width: 768px) {
    font-size: 1.75rem ;
    line-height: 1.3em ;
  }

  @media (max-width: 600px) {
    font-size: 40px;
    line-height: 55px;
    margin-top: 0rem;
    text-align: center;
  }
`;

export const Logos = styled.div`
  @media (max-width: 600px) {
    justify-content: center;
  }
`;

export const SupplText = styled.p`
  font-weight: 500;
  line-height: 32px;
  font-size: 1.2rem;
  font-weight: 500;
  color: rgb(139, 148, 158);
  letter-spacing: -0.48px;
  line-height: 1.5em;
  font-family: "Mona Sans", "MonaSansFallback", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji";

  @media (max-width: 450px) {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const TextField = styled.div`
  padding: 0.7rem;
  background: var(--main-bg);
  color: #fff;
  box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.16);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > input {
    width: 16rem;
    font-size: 14px;
  }

  @media (max-width: 450px) {
    width: 94%;
    padding: 0.7rem 3%;
    margin: 2rem 0 0 0;

    > input {
      width: 50%;
      font-size: 16px;
    }
  }
`;

export const Breadcrumb = styled.div`
  display: flex;
  align-items: Center;
  gap: 10px;
  font-size: 12px;
  color: #000;
`;

